<template>
  <div class="full-screen">
    <FullView :details="detailsInfo" v-if="isShow"></FullView>
  </div>
</template>

<script>
import { examFullViewDetails, decorateStudentDetails } from "@/utils/newDecorate";
import FullView from "@/components/NewDecoratorFullScreen.vue";

export default {
  name: "FullScreen",
  components: {
    FullView,
  },
  props: {},
  data() {
    return {
      detailsInfo: {},
      isShow: false,
    };
  },
  computed: {},
  methods: {
    getDetails() {
      if (localStorage.getItem("role") == 4) {
        decorateStudentDetails({ exam_id: this.$route.query.exam_id, user_id: this.$route.query.user_id }).then(({ data, msg }) => {
          this.detailsInfo = data;
          this.isShow = true;
        });
      } else {
        examFullViewDetails().then(({ data, msg }) => {
          this.detailsInfo = data;
          this.isShow = true;
        });
      }
    },
  },
  mounted() {
    this.getDetails();
  },
};
</script>

<style scoped lang="scss">
.full-screen {
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
}
</style>
