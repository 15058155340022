<template>
  <div class="new-decorator-full-screen" :style="{ margin: roleType ? '0 24px' : '' }">
    <div class="page-view">
      <div style="height: 100%">
        <!-- <el-scrollbar> -->
        <!-- 店铺设置 -->
        <div class="dz-logo">
          <img src="../assets/images/no-logo.png" class="logo" v-if="!viewDetails.logo_info" />
          <img :src="viewDetails.logo_info" class="logo" v-if="viewDetails.logo_info" />
          <img src="../assets/images/no-sign-logo.png" style="width: 100%; height: 100%" v-if="!viewDetails.dz_info" />
          <img :src="viewDetails.dz_info" style="width: 100%" v-if="viewDetails.dz_info" />
        </div>
        <!-- 分类设置 -->
        <div class="classify-box">
          <span>Home Page</span><span v-for="item in viewDetails.class_info">{{ item.store_class_name }}</span>
        </div>
        <!-- banner设置 -->
        <div class="banner-box">
          <img src="../assets/images/no-banner.png" style="width: 100%" v-if="!viewDetails.poster_info.length" />
          <el-carousel class="carousel-box" v-else>
            <el-carousel-item v-for="item in viewDetails.poster_info" :key="item" style="max-height: 630px">
              <img :src="item" style="width: 100%" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 排序设置 -->
        <div class="sort-box">
          <div class="sort-item" :style="{ color: !index ? '#1cb064' : '', background: !index ? '#fafafa' : '' }" v-for="(item, index) in viewDetails.sort_info" v-if="viewDetails.sort_info?.length">
            <span style="margin-right: 6px"> {{ item }} </span>
            <i class="iconfont" style="font-size: 6px">&#xe600;</i>
          </div>
          <div class="sort-item" v-for="(item, index) in 4" v-if="!viewDetails.sort_info?.length">
            <span style="margin-right: 6px"></span>
            <i class="iconfont" style="font-size: 6px">&#xe600;</i>
          </div>
        </div>
        <!-- 商品发布 -->
        <div class="goods-box">
          <div class="goods-item" v-for="(item, index) in viewDetails.goods_info" v-if="viewDetails.goods_info.length" @click="lookGoods(item.id)">
            <img :src="item.goods_image[0].image_url" style="width: 100%" />
            <div style="color: #cc0000; margin: 6px 0">￥{{ item.goods_price }}</div>
            <div class="singe-line">{{ item.goods_name }}</div>
          </div>
          <template v-if="!viewDetails.goods_info.length">
            <div class="goods-item" v-for="(item, index) in 4">
              <div class="goods-img">
                <img src="../assets/images/no-data.png" style="width: 100%" />
              </div>
            </div>
          </template>
        </div>
        <!-- </el-scrollbar> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewDecoratorFullScreen",
  components: {},
  props: {
    details: {
      type: Object,
    },
    roleType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      viewDetails: {
        class_info: [],
        dz_info: "",
        goods_info: [],
        logo_info: "",
        poster_info: [],
        sort_info: [],
      },
    };
  },
  computed: {},
  methods: {
    lookGoods(id) {
      let role = localStorage.getItem("role");
      let url = this.$router.resolve({ path: role == 4 ? "/judges/teacher/goods/detail" : role == 6 ? "/teacher/newDecorator/detail" : "/newDecorator/detail", query: { id } });
      window.open(url.href);
    },
  },
  mounted() {
    this.viewDetails.class_info = this.details.class_info;
    if (this.details.dz_info) this.viewDetails.dz_info = this.details.dz_info[0];
    this.viewDetails.goods_info = this.details.goods_info;
    if (this.details.logo_info) this.viewDetails.logo_info = this.details.logo_info[0];
    if (this.details.poster_info) this.viewDetails.poster_info = this.details.poster_info;
    this.viewDetails.sort_info = this.details.sort_info;
  },
};
</script>

<style scoped lang="scss">
.new-decorator-full-screen {
  box-sizing: border-box;
  height: 100%;
  .page-view {
    height: 100%;
    background: #fff;
    .dz-logo {
      max-height: 120px;
      display: flex;
      align-items: center;
      position: relative;
      .logo {
        margin-left: 20%;
        height: 83.5%;
        position: absolute;
        z-index: 1;
      }
    }
    .classify-box {
      padding-left: 20%;
      line-height: 30px;
      background: #fff;
      > span {
        margin-right: 12px;
        font-size: 12px;
        color: #666;
      }
    }
    .banner-box {
      width: 100%;
      height: 0;
      padding-bottom: 32.8125%;
      background: #fafafa;
      position: relative;
      .carousel-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        :deep(.el-carousel__container) {
          height: 100%;
        }
        :deep(.el-carousel__item) {
          display: flex;
          align-items: center;
          justify-content: center;
          .item {
            max-width: 100%;
            max-height: 100%;
          }
        }
        :deep(.el-carousel__button) {
          width: 10px;
          height: 10px;
          border: none;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.2);
        }
        :deep(.is-active .el-carousel__button) {
          background: #1cb064;
        }
      }
    }
    .sort-box {
      padding-left: 20%;
      margin-top: 7px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background: #fff;
      .sort-item {
        min-width: 40px;
        height: 22px;
        font-size: 12px;
        border: 1px solid #e8e8e8;
        margin: 0 7px 7px 0;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .goods-box {
      padding: 0 20% 40px 20%;
      background: #fff;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .goods-item {
        color: #000;
        margin: 0 20px 20px 0;
        cursor: pointer;
      }
      .singe-line {
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }
  :deep(.el-carousel__button) {
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  :deep(.is-active .el-carousel__button) {
    background: #1cb064;
  }
}
</style>
